import './Header.css';
/*import React, { useContext} from 'react';*/
/*import { GithubContext } from '../context/context';*/
/*import { RouterProvider, createBrowserRouter } from 'react-router-dom';*/
import { Link } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { useMsal } from "@azure/msal-react";
/*import { useIsAuthenticated } from "@azure/msal-react";*/

const Header = (props) => {
    console.log(props)



    //let userName = localStorage.getItem("userName")
    //let userPic = localStorage.getItem("userPic")
    const [loginActivity, setloginActivity] = useState(null);
    //const [graphAPIAccessToken, setGraphAPIAccessToken] = useState(null);
    //const [userImageUrl, setUserImageUrl] = useState(null);
    const userId = props.userId;
    const { instance } = useMsal();

    const [sticky, setSticky] = useState(false);

    console.log(window.location.pathname);


    useEffect(() => {

        if (window.location.pathname === "/") {
            if (userId != null) {
                fetchOpportunities(userId);
            }
        }

        function fetchOpportunities(userId) {
            
            //console.log("LogActivity")
            fetch('logincall/LoginActivity/' + userId).then(response => {
                return response.json();
            }).then(data => {
                debugger;
                //console.log(data);
                //setData(data[0].loginTime);

                setloginActivity(data[0].loginTime)
                localStorage.setItem("logintime", data[0].loginTime)
                //localStorage.setItem('logintime', `${data.map((Opp, index) => (Opp.loginTime))}`);

            })
        }
    }, [userId]);


    useEffect(() => {
        const handleScroll = () => {
            // setSticky(window.scrollY > 50);
        }
        window.addEventListener('scroll', handleScroll);
        return () => window.removeEventListener('scroll', handleScroll)
    }, [])


    const handleLogout = (logoutType) => {
        if (logoutType === "redirect") {
            localStorage.clear();
            instance.logoutRedirect();

        }
    }
    //let imgUrl
    //if (userPic) { imgUrl = userPic }
    //else {
    //    imgUrl = '../image/user.jpg'
    //}











    return (







        <header className={`${sticky ? 'sticky' : ''}`}>
            <figure>
                <Link to='/oppurtunity-list'>
                    <img src='./image/logo.svg' alt="Deloitte Logo" className='logo' />
                </Link>
            </figure>
            <div className='user-profile'>
                {/* <div   className='profile-info'>
                    <img src={imgUrl} alt='' />
                    <div className='user-name'>{userName }</div>
                    <i onClick={() => handleLogout("redirect")} className='demo-icon icon-exit_special'>&#x60;</i>
                </div>
                <div className='last-login-info'>
                    Last Login: {localStorage.getItem("logintime") }
                </div> */}
                <div className="dropdown dropright" >
                    <button role='button' className="dropdown-toggle " id="dropdownMenuButton" aria-haspopup="true" data-toggle="dropdown" aria-label="User Profile" aria-expanded="false">
                        <img src={props.userPic == null ? "../image/user.jpg" : props.userPic} className="userProfile" alt='' />
                    </button>
                    <div className="dropdown-menu" aria-labelledby="dropdownMenuButton">
                        <div className='drop-block'>
                            <img src={props.userPic == null ? "../image/user.jpg" : props.userPic} alt='' />
                            <div className='drop-info'>
                                <div className='user-name'>{props.userName}</div>
                                <div className='user-profile'>Last Login: {loginActivity === null ? localStorage.getItem("logintime") : loginActivity}</div>
                            </div>
                        </div>
                        <button role='button' aria-label="Logout"  className='logout-link' onClick={() => handleLogout("redirect")}>Logout</button>
                    </div>
                </div>
            </div>
        </header>


    )
}

export default Header;